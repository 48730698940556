<template>
  <div class="modal-inner-container">
    <div class="w-full">
      <div class="text-n-4xl font-bold">
        {{$t('security.twofa.twofa_reset')}}
      </div>
      
      <div class="text-n-md font-medium mt-n-3xl">
        {{$t('security.twofa.you_have_successfully_reset')}}
      </div>
    </div>

    <ButtonV2
      @onClick="hideModal"
      :label="$t('navigation.close')"
      testId="btn-close"
      class="mt-n-5xl"
      submit
      wide
    />
  </div>
</template>

<script>
  import ButtonV2 from '@/stories/misc/ButtonV2';

  export default {
    name: 'TwoFactorAuthenticationReset',

    components: {
      ButtonV2,
    },
  };
</script>

<style scoped>
</style>
